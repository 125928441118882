<template>
  <div class="bg-white">
    <div class="flex items-center justify-between px-4 py-4 mt-2 bg-white">
      <h3 class="text-xl font-semibold uppercase text-oDark">
        Rider Promotion
      </h3>

      <DateRangePicker
        @apply-date="onApplyFilterDateRange"
        @calcel-date="onApplyFilterDateRange"
      />
    </div>

    <div class="flex flex-col px-4 mt-4 mb-10 md:items-center md:flex-row">
      <card-tax-stat
        title="Total Promotions"
        :value="getTotalPromos"
        variant="green"
        class="mr-3"
      />
      <card-tax-stat
        title="Trip Taken"
        :value="getTotalTripTaken"
        variant="orange"
        class="mr-3"
      />
      <card-tax-stat
        title="Discounts"
        :value="getTotalDiscountAmount"
        variant="white-purple"
        class="mr-3"
      />
    </div>

    <FSTable
      :fst-id="`riderPromotion`"
      :endpoint="`/dashboard/riders/${$route.params.id}/promotion-records/`"
      :headers="tableHeaders"
      @meta="(e) => (indexMetaData = e)"
    >
      <template #default="{ data }">
        <FSTableRow
          v-for="(item, itemIndex) in data"
          :key="itemIndex"
          text-fallback-always
        >
          <FSTableRowItem
            :text="item.promo_id"
            :truncate="-5"
            :to="{ name: 'ViewPromotion', params: { id: item.pass_id } }"
          />

          <FSTableRowItem :text="item.promo_title" />

          <FSTableRowItem :text="item.promo_category" />

          <FSTableRowItem :text="item.promo_code" />

          <FSTableRowItem :text="item.updated_at" date />

          <FSTableRowItem :text="item.expired_at" date />

          <FSTableRowItem :text="item.num_of_trips_availed" />

          <FSTableRowItem :text="item.num_of_trips_left" />

          <FSTableRowItem :text="`${symbol}${item.discount_amount}`" />

          <!-- <FSTableRowItem>
            <oto-eye-icon v-tooltip.bottom="'View & Download Invoice'" />
          </FSTableRowItem>

          <FSTableRowItem>
            <div
              class="refund-action"
              :class="item.is_refunded ? 'refunded' : 'refund'"
              @click="showRefundPopup(item)"
            >
              {{ item.is_refunded ? 'Refunded' : 'Refund' }}
            </div>
          </FSTableRowItem> -->
        </FSTableRow>
      </template>
    </FSTable>
  </div>
</template>

<script>
import DateRangePicker from '@/components/picker/date-range/DateRangePicker.vue'
// import OtoEyeIcon from '@/components/ui/OtoEyeIcon'
// import RefundPopup from '@/views/transactions/RefundPopup.vue'
import CardTaxStat from '@/components/cards/TaxStat'

import {
  FSTable,
  FSTableRow,
  FSTableRowItem,
  onApplyFilterDateRange,
} from '@/components/fs-table'

export default {
  name: 'ViewRiderUserPromotion',
  components: {
    DateRangePicker,
    // OtoEyeIcon,
    // RefundPopup,
    CardTaxStat,
    FSTable,
    FSTableRow,
    FSTableRowItem,
  },
  data() {
    return {
      symbol: '$',
      indexMetaData: {
        summary: {
          total_promos: '0',
          trip_taken: '0',
          total_discounted: 0,
        },
        count: {
          total: 0,
        },
      },
      tableHeaders: [
        { text: 'Promo ID', width: '10%', sort: null },
        { text: 'Promo Title', width: '10%', sort: 'title' },
        { text: 'Promo Type', width: '10%', sort: null },
        { text: 'Code', width: '10%', sort: 'code' },
        { text: 'Applied Date', width: '15%', sort: 'updated_at' },
        { text: 'Expired Date', width: '15%', sort: 'expired_at' },
        { text: 'Trip Availed', width: '10%', sort: null },
        { text: 'Trip Left', width: '10%', sort: null },
        { text: 'Discount Amount', width: '10%', sort: null },
      ],
    }
  },
  computed: {
    getTotalPromos() {
      return `${this.indexMetaData?.summary?.total_promos}`
    },
    getTotalTripTaken() {
      return `${this.indexMetaData?.summary?.trip_taken}`
    },
    getTotalDiscountAmount() {
      return `${this.symbol} ${this.indexMetaData?.summary?.total_discounted}`
    },
  },
  mounted() {
    this.symbol = this?.$org?.default_currency?.symbol || '$'
  },
  methods: {
    onApplyFilterDateRange,
  },
}
</script>
